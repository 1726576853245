<template lang="">
  <div class="ownerDetails">
    <div class="commoncard">
      <form>
        <div class="form-group">
          <input
            :class="{ 'errorField': state.formFields.fullName.errorMessage }"
            type="text"
            class="form-control"
            id="full-name"
            placeholder="Full Name"
            v-model="state.formFields.fullName.value"
            @blur="checkFieldsError('fullName')"
          />
          <span class="errorDefault" v-if="state.formFields.fullName.errorMessage">{{ state.formFields.fullName.errorMessage }}</span>

          <input
          :class="{ 'errorField': state.formFields.mobileNumber.errorMessage }"
            type="number"
            class="form-control mt-3"
            id="mob-number"
            placeholder=" Mobile Number"
            maxlength="10"
            v-model="state.formFields.mobileNumber.value"
            @blur="checkFieldsError('mobileNumber')"
          />
          <span class="errorDefault" v-if="state.formFields.mobileNumber.errorMessage">{{ state.formFields.mobileNumber.errorMessage }}</span>
          <input
          :class="{ 'errorField': state.formFields.dob.errorMessage }"
            type="date"
            class="form-control mt-3"
            id="mob-number"
            placeholder="Date Of Birth "
            v-model="state.formFields.dob.value"
            @blur="checkFieldsError('dob')"
          />
          <span class="errorDefault" v-if="state.formFields.dob.errorMessage">{{ state.formFields.dob.errorMessage }}</span>

          <input
          :class="{ 'errorField': state.formFields.panNumber.errorMessage }"
            type="text"
            class="form-control mt-3"
            placeholder="PAN Number"
            v-model="state.formFields.panNumber.value"
            @blur="checkFieldsError('panNumber')"
          />
          <span class="errorDefault" v-if="state.formFields.panNumber.errorMessage">{{ state.formFields.panNumber.errorMessage }}</span>
          <h4 class="mt-3">Gender</h4>
          <div class="d-flex gap-3 align-items-center">
            <div class="d-flex gap-2 align-items-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="male"
                v-model="state.formFields.gender.value"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Male
              </label>
            </div>
            <div class="d-flex gap-2 align-items-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="female"
                v-model="state.formFields.gender.value"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Female
              </label>
            </div>
            <div class="d-flex gap-2 align-items-center">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value="others"
                v-model="state.formFields.gender.value"
              />
              <label class="form-check-label" for="flexRadioDefault3">
                Others
              </label>
            </div>
          </div>
          <h4 class="mt-3">Address</h4>
          <input
            :class="{ 'errorField': state.formFields.address1.errorMessage }"
            type="text"
            class="form-control"
            id="address-1"
            placeholder="Address Line 1"
            v-model="state.formFields.address1.value"
            @blur="checkFieldsError('address1')"
          />
          <span class="errorDefault" v-if="state.formFields.address1.errorMessage">{{ state.formFields.address1.errorMessage }}</span>
          <input
            :class="{ 'errorField': state.formFields.address2.errorMessage }"
            type="text"
            class="form-control mt-3"
            id="address-2"
            placeholder="Address Line 2"
            v-model="state.formFields.address2.value"
            @blur="checkFieldsError('address2')"
          />
          <span class="errorDefault" v-if="state.formFields.address2.errorMessage">{{ state.formFields.address2.errorMessage }}</span>
          <div class="manage-selects my-3">
            <div class="">
              <select class="form-select" aria-label="Default select example" name="Country" v-model="state.formFields.country.value" placeholder="Country" @blur="checkFieldsError('country')" :class="{ 'errorField': state.formFields.country.errorMessage }">
                <option disabled value="0">Country</option>
                <option value="1">India</option>
                <option value="2">USA</option>
                <option value="3">CHINA</option>
              </select>
              <span class="errorDefault" v-if="state.formFields.country.errorMessage">{{ state.formFields.country.errorMessage }}</span>
            </div>
            <div class="">
              <select class="form-select" aria-label="Default select example" name="State" v-model="state.formFields.state.value" @blur="checkFieldsError('state')" :class="{ 'errorField': state.formFields.state.errorMessage }">
                <option selected disabled value="0">State</option>
                <option value="1">Maharastra</option>
                <option value="2">WB</option>
                <option value="3">BIHAR</option>
              </select>
              <span class="errorDefault" v-if="state.formFields.state.errorMessage">{{ state.formFields.state.errorMessage }}</span>
            </div>
            <div class=" ">
              <select class="form-select" aria-label="Default select example" name="City" v-model="state.formFields.city.value" @blur="checkFieldsError('city')" :class="{ 'errorField': state.formFields.city.errorMessage }">
                <option selected disabled value="0">City</option>
                <option value="1">KOLKATA</option>
                <option value="2">PATNA</option>
                <option value="3">RANCHI</option>
              </select>
              <span class="errorDefault" v-if="state.formFields.city.errorMessage">{{ state.formFields.city.errorMessage }}</span>
            </div>
            <div class=" ">
              <input type="text" class="form-control" placeholder="PIN Code" v-model="state.formFields.pin.value" @blur="checkFieldsError('pin')" :class="{ 'errorField': state.formFields.pin.errorMessage }"/>
              <span class="errorDefault" v-if="state.formFields.pin.errorMessage">{{ state.formFields.pin.errorMessage }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <button class="backbutton">Back</button>
            </div>
            <div class="col-md-6">
              <button class="btn commonButton" @click="handleFormSubmission()">Proceed </button>
            </div>
          </div>
        </div>

        <div>

        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue';
export default {
  name: "OwnerDetails",
  components: {

  },
  props: [],
  setup() {
    const state = reactive({
      formFields: {
        fullName: {
          value: "",
          errorText: "Full Name is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        mobileNumber: {
          value: "",
          errorText: "Mobile Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        panNumber: {
          value: "",
          errorText: "PAN Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        dob: {
          value: "",
          errorText: "Date of Birth is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        gender: {
          value: "",
          errorText: "Date of Birth is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        address1: {
          value: "",
          errorText: "Address Line 1 is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        address2: {
          value: "",
          errorText: "Address Line 2 is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        country: {
          value: "0",
          errorText: "Country is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        state: {
          value: "0",
          errorText: "State is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        city: {
          value: "0",
          errorText: "City is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        pin: {
          value: "",
          errorText: "PIN Code is Required",
          errorMessage: "",
          stepsMandatory: true
        },


      }
    })

    function checkFieldsError(fieldName) {
      if (!state.formFields[fieldName].value || state.formFields[fieldName].value == 0) {
        state.formFields[fieldName].errorMessage = state.formFields[fieldName].errorText;
      } else {
        state.formFields[fieldName].errorMessage = ""
      }
    }

    function handleFormSubmission() {
      console.log("Hello World")
    }

    return {
      state,
      handleFormSubmission,
      checkFieldsError
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
</style>
