<template>
        <innerHeader/>
        <kycDocuments/>
</template>
<script>
        import innerHeader from '@/components/header/innerHeader.vue';
        import kycDocuments from '@/components/ckyc/kycDocuments.vue';
        export default{
                name: "OVDs",
                components:{innerHeader, kycDocuments},
                setup(){

                }
        }

</script>
<style lang="scss"></style>