<template lang="">
  <div>
    <div class="headingcards mt-3 bg-white" key="Plan Summary">
      <h3>Plan Summary</h3>
      <SkeletonLoader
        :key="index"
        width="50%"
        height="20"
        class="skeletonloader"
      />
      <div class="d-flex align-items-center mt-3 justify-content-between">
        <p>Plan Type</p>
        <SkeletonLoader
          :key="index"
          width="45%"
          height="21"
          class="skeletonloader"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <p>IDV Cover</p>
        <SkeletonLoader
          :key="index"
          width="50%"
          height="21"
          class="skeletonloader"
        />
      </div>

      <hr />
      <div class="d-flex align-items-center mt-3 justify-content-between">
        <p>Premium Amount</p>
        <SkeletonLoader
          :key="index"
          width="50%"
          height="21"
          class="skeletonloader"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <p>GST @18%</p>
        <SkeletonLoader
          :key="index"
          width="50%"
          height="21"
          class="skeletonloader"
        />
      </div>
      <hr />
      <div class="d-flex align-items-center justify-content-between">
        <h4>Total Amount</h4>
        <SkeletonLoader
          :key="index"
          width="60%"
          height="21"
          class="skeletonloader"
        />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <SkeletonLoader
          :key="index"
          width="100%"
          height="41"
          class="skeletonloader"
        />
      </div>
      <div class="col-md-6">
        <SkeletonLoader
          :key="index"
          width="100%"
          height="41"
          class="skeletonloader"
        />
      </div>
      <div class="col-md-6">
        <SkeletonLoader
          :key="index"
          width="100%"
          height="41"
          class="skeletonloader"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { SkeletonLoader } from "vue3-loading-skeleton";
export default {
  components: { SkeletonLoader },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../..//assets/scss/components/Loaders.scss";
</style>
