<template>
    <div>
        <fileUpload />
    </div>
</template>

<script>
import fileUpload from '@/components/fileUpload/fileUpload.vue';
export default {
  name: 'FileUploadView',
  components: {
    fileUpload,
  }
}
</script>

<style scoped lang="scss">
</style>