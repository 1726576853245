<template lang="">
    <div class="commonmargin" >
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 col-md-4 col-xl-4 text-center kycform">
            
          <h1 class="headings text-danger my-3">Your CKYC failed.</h1>
          <h1 class="headings my-3">Pease complete KYC via OVD</h1>
          <hr />
         <div class="row justify-content-center">
         <div class="col-12 col-lg-12 col-md-12 col-xl-12">
          <div class="card">
            <select class="form-select p-2"  aria-label="Default select example">
             <option selected>Select Document Category</option>
             <option value="1">10K</option>
             <option value="2">10K</option>
             <option value="3">10K</option>
</select>
<select class="form-select mt-3 p-2"  aria-label="Default select example">
  <option selected>Select Document Type</option>
  <option value="1">10K</option>
  <option value="2">10K</option>
  <option value="3">10K</option>
</select>
<label class="headinglabel mb-1" >
    Documents
</label>
<input type="file" id="upload" class="custom-file-input p-3">
          <button class="commonButton mt-3" >Proceed</button>
          <button class="backbutton mt-2" @click="goBack()">Back</button>
          
          
          </div>
         </div>
         </div>
        </div>
      </div>
    </div>
  </template>
<script>
import { useRouter } from 'vue-router';
export default {
  name: "KycDocumentsUpload",
  setup() {
    const router = useRouter();
    function goBack() {
      router.go(-1);
    }

    return {
      goBack
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/Kyc.scss";
</style>