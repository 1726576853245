<template lang="">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-12 col-md-12 col-xl-12">
      <div class="productheader">
        <div class="d-flex justify-content-between align-items-center flex-wrap">
          <div class="w-50 d-flex gap-2 flex-wrap">
            <img
              class="productImage"
              :src=this.insCompanyLogo
            />
            <div class="d-flex align-items-center flex-wrap gap-2">
                <h2 class="productName mb-0">{{ this.productName}}</h2>
            <span class="productSpan">({{this.policyType}})</span>
            </div>
          </div>
          <div class="w-50 ">
           
            <h2 class="journeyId text-end">
              <span class="productSpan">Reference Number</span> : {{journey_id}}
            </h2>
            <h2 class="journeyId text-end mt-2">
                <span class="productSpan">Premium Amount</span>
               : &#8377; {{currency(totalPremiumWithTax)}}
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="m-0" />
</template>
<script>
export default {
    name: "productheader",
  components: {
  },
  mounted() {
    if(JSON.parse(sessionStorage.getItem("journey_id"))){
    this.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
    }
    this.getSelectedPlanData();
  },
  data() {
    return {
        journey_id: "",
        selectedPlan:"",
        productName:"",
        policyType:"",
        insCompanyLogo:"",
        totalPremiumWithTax:"",

    };
  },
  methods:{
    getSelectedPlanData() {
      //  An interval to check for the selected plan
      const timer = setInterval(() => {
        const planData = sessionStorage.getItem("selected_plan_data");
        if (planData) {
          this.selectedPlan = JSON.parse(planData);
          if (this.selectedPlan) {
            clearInterval(timer);
            this.productName = this.selectedPlan.insuranceAndProducts.productName;
            this.policyType = this.selectedPlan.insuranceAndProducts.policyType;
            this.insCompanyLogo = this.selectedPlan.supportingData.insCompanyLogo;
            this.totalPremiumWithTax = this.selectedPlan.productDetails.totalPremiumDetails[0].totalPremiumWithTax;
          }
        }
      }, 200);
    },
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  }
};
</script>
<style scoped lang="scss">

@import '../../assets/scss/components/productDetailsHeader.scss';



</style>
