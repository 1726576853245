<template lang="">
    <div>
        <confirmPay/>
    </div>
</template>
<script>
import ConfirmPay from '@/components/selectCard/confirmPay.vue';

export default {
    components:{
        ConfirmPay
    }
}
</script>
<style lang="">
    
</style>