<template lang="">
  <div class="commoncard">
    <div>
      <SkeletonLoader
        v-for="index in 10"
        :key="index"
        width="100%"
        height="41"
        class="skeletonloader"
        
      />
    </div>
  </div>
</template>
<script>
import { SkeletonLoader } from "vue3-loading-skeleton";
export default {
  components: { SkeletonLoader },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/Loaders.scss"
</style>
