
/**
 * A class for AES256 symmetric encryption.
 * 
 * @class
 */
import CryptoJS from "crypto-js";

const iv=CryptoJS.enc.Utf8.parse('SgVkYp3s5v8y/B?E')
const algo_mode=CryptoJS.mode.CBC
var secret_key=""
function aes_256_secretKey(key)
{
    secret_key=CryptoJS.enc.Utf8.parse(key)
    return secret_key
    
}
export const aes_256_encrypt=(localStorageSecretKey,data)=>{
    console.log("secret_key........",aes_256_secretKey(localStorageSecretKey))
    var exdata = JSON.stringify(data);
    console.log("req data",exdata)
    var encrypted_data = CryptoJS.AES.encrypt(exdata, aes_256_secretKey(localStorageSecretKey), { iv: iv, mode:algo_mode});
    console.log("encrypted_data",encrypted_data)
    return encrypted_data.toString();
}
export const aes_256_decrypt=(localStorageSecretKey,enc_data)=>{
    var decrypted =  CryptoJS.AES.decrypt(enc_data, aes_256_secretKey(localStorageSecretKey), { iv: iv, mode: algo_mode});
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
}
// class AES256SymmetricEncryption{
//     /**
//      * Creates a new instance of the Crypto class with the given secret key.
//      * @param {string} secret_key - The secret key to use for encryption and decryption.
//      * @returns None
//      */
//     constructor(secret_key) {
//         this.secret_key=CryptoJS.enc.Utf8.parse(secret_key)
//         this.iv=CryptoJS.enc.Utf8.parse('SgVkYp3s5v8y/B?E')
//         this.algo_mode=CryptoJS.mode.CBC
//     }

//     /**
//      * Encrypts the given data using AES-256 encryption.
//      * @param {Object} data - The data to be encrypted.
//      * @returns {string} - The encrypted data as a string.
//      */
//     aes_256_encrypt(data){
//         var data = JSON.stringify(data);
//         var encrypted_data = CryptoJS.AES.encrypt(data, this.secret_key, { iv: this.iv, mode:this.algo_mode});
//         return encrypted_data.toString();
//     }
    
//     /**
//      * Decrypts the given data using AES-256 encryption.
//      * @param {string} enc_data - The data to be decrypted.
//      * @returns {object} - The decrypted data in JSON format.
//      */
//     aes_256_decrypt(enc_data){
//         var decrypted =  CryptoJS.AES.decrypt(enc_data, this.secret_key, { iv: this.iv, mode: this.algo_mode});
//         return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
//     }
// }
