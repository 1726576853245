<template>
    <div class="modal-overlay breakupmodal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title text-align-center d-flex w-100 justify-content-between align-items-center">
                      
                        <h2 style="color: red;">Based on Proposal Details, Your premium has been revised</h2>
                        <span  @click="emitClose()">
            <img src="/images/close-logo.svg" class="close_icon"  />
            </span>
                    </h5>
                </div>
                <div class="modal-body mt-2">
                    <div class="d-flex justify-content-between text-align-center mt-3">
                    <span><h6>totalPremiumValueBeforeTax</h6>
                        <h6>{{updatedPremium?.productDetails?.totalPremiumDetails[0]?.totalPremiumValueBeforeTax}}</h6></span>
                        <span><h6>totalTax</h6>
                            <h6>{{updatedPremium?.productDetails?.totalPremiumDetails[0]?.totalTax}}</h6></span>
                            <span><h6>totalPremiumWithTax</h6>
                                <h6>{{updatedPremium?.productDetails?.totalPremiumDetails[0]?.totalPremiumWithTax}}</h6></span>
                            </div>
                            
                    <div class="modal-footer mt-5">
                        <!-- <button type="button" class="btn commonButton" @click="goToMQ" >go to mq</button> -->
                        <!-- <button type="button" class="btn commonButton" @click="openOtpModal()">Submit</button> -->
                    <button type="button" class="btn commonButton" @click="continueNewPremium()">Continue with this Premium</button>
                </div>
                
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
    return{
        selectedPlanData:'',
        filteredaddon:[],
        updatedPremium:''
    }
    },
    mounted(){
        this.updatedPremium= JSON.parse(sessionStorage.getItem('updated_premium'))
          
        },
   
    methods:{
        emitClose(){
            this.$emit('emitVerifyClose')
        },   
        openOtpModal(){
            
            this.$emit('emitVerifyClose')
            this.$emit('openOtpModal')
            
        },
        continueNewPremium(){
            this.$emit('emitcontinueNewPremium')
        },
        goToMQ() {
      // Navigate to the 'mq' route
      this.$router.push({ name: 'MultipleQuotes' });
    }    
    }
    
   

}
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup{
    position: relative;
    background-color: #FFF;
    max-width: 480px;
    height: 480px;
    overflow-y: auto;
    width: 90%;
    border-radius: 12px;
    padding: 48px 12px 12px;
}
#endclose{
    position: relative;
    left: 0;
}
</style>