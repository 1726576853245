<template>
    <div> 
        <div class="inner-header-bg">
           <innerheader />
        </div>
        <dashboard />
    </div>
</template>

<script>
// @ is an alias to /src
import dashboard from "@/components/dashboard/dashboard.vue"
import innerheader from "@/components/header/innerHeader.vue";

export default {
  name: 'dashbord',
  components: {
     innerheader,
     dashboard
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/components/dashboard.scss';
</style>