<template lang="">
  <div class="">
    <div class="d-flex justify-content-between align-items-center">
        <div style="width:70%">
            <SkeletonLoader
            :key="index"
            width="40%"
            height="40"
            class="skeletonloader m-0"
            />
            <SkeletonLoader
            :key="index"
            width="50%"
            height="10"
            class="skeletonloader mt-1"
            />
            <div class="d-flex flex-wrap gap-2 mt-2">
            <SkeletonLoader
                :key="index"
                width="30%"
                height="10"
                class="skeletonloader m-0"
            />
            <SkeletonLoader
                :key="index"
                width="50%"
                height="10"
                class="skeletonloader m-0"
            />
            <SkeletonLoader
                :key="index"
                width="50%"
                height="10"
                class="skeletonloader m-0"
            />
            <SkeletonLoader
                :key="index"
                width="20%"
                height="10"
                class="skeletonloader m-0"
            />
            </div>
           
        </div>
        <div style="width:20%">
            <SkeletonLoader
                :key="index"
                width="100%"
                height="30"
                class="skeletonloader m-0" style="border-radius:50px"
            />
            <SkeletonLoader
                :key="index"
                width="100%"
                height="20"
                class="skeletonloader mt-2"
            />
          
            
            </div>
    </div>
  </div>
</template>
<script>
import { SkeletonLoader } from "vue3-loading-skeleton";
export default {
  components: { SkeletonLoader },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/Loaders.scss";
</style>
