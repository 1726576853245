<template lang="">
    <div>
        <innerHeaderDiy/>
        <inspection/>
    </div>
</template>
<script>
import inspection from '@/components/pageLoaders/thankYou.vue';
import innerHeaderDiy from "@/components/header/innerHeaderDiy";


export default {
    components:{
        inspection,
        innerHeaderDiy
    }
}
</script>
<style lang="">
    
</style>