<template lang="">
   
    <div>
        <div
          class="card"
          :class=[getCurrentBg]
        >
          <!-- <router-link to="/"> -->
            <div @click="goback()">
              <img src="../../assets/images/backarrow.svg" alt="" class="backarrow cursor-pointer">
           
          </div>
          <img :src="getCurrentImage" class="rightsideimage"  />
          <!-- </router-link> -->
        </div>
        
    </div>
</template>
<script>
import { getLandingImgs } from "@/mixins/common";
export default {
  mounted() {
    this.motorProductType = this.$store.state.motorProductType;
    if (this.motorProductType === "4W-Pvt") {
      this.items[0].newJourneyBg = getLandingImgs()["CARBG"]
    }
    else {
      this.items[0].newJourneyBg = getLandingImgs()["BIKEBG"]
    }
  },
  data() {

    return {
      motorProductType: '',
      items: [
        {
          newJourneyBg: getLandingImgs()["CARBG"]  // Ensure this returns a valid URL
        },
      ],
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    newJourneyBg(imageUrl) {
      if (imageUrl) {
        // Check if imageUrl is defined
        return {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          borderRadius: "30px",
          backgroundPosition: "bottom",
          width: "100%",
          height: "150px", // Adjust the height as needed
          zIndex: "0",
          marginTop: "-1rem",
          position: "relative",
          border: "none",
        };
      } else {
        return {}; // Return an empty object or handle the case where imageUrl is undefined/null
      }
    },
  },
  computed: {
    getCurrentImage() {
      if (this.$store.state.motorProductType === '4W-Pvt') {
        return getLandingImgs()['CAR'];
      } else {
        return getLandingImgs()['BIKE'];
      }
    },
    getCurrentBg(){
      if(this.$store.state.motorProductType === '4W-Pvt') {
        return 'cardCarBg';
      } else {
        return 'cardBikeBg';
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/journeyHeader.scss";
.cardCarBg{
  background: linear-gradient(180deg, hsla(30, 99%, 73%, 1) 0%, hsla(48, 99%, 65%, 1) 100%);
  padding: 20px;
  border-radius: 0 0 40px 40px;
  border: none;
  margin-top: 3rem;
}
.cardBikeBg{
  background: linear-gradient(180deg, hsla(133, 18%, 75%, 1) 0%, hsla(146, 20%, 79%, 1) 100%);
  padding: 20px;
  border-radius: 0 0 40px 40px;
  border: none;
  margin-top: 3rem;
}

.rightsideimage{
  width: 17%;
    margin-left: auto;
    margin-bottom: -12px;
    margin-top: 0.2rem;
}
.backarrow{
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 28px;
            left: 28px;
            border: 1px solid $text-color;
            padding: 2px;
            border-radius: 3px;
            stroke: $text-color;
            fill:$text-color;
            cursor: pointer;
  
        }
</style>