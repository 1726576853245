<template lang="">
  <ProductDetailsHeader/>
  <div class="error-page">
    <div class="text-center">
      <img src="/images/error (2).gif" class="m-auto" />
      <h4 class="text-danger  text-center">Error!</h4>
      <p class="text-center">{{this.msg}}</p>
      
      <button
        class="erroButton"
        @click=goBack()
      >
        Go Back
      </button>
      <div></div>
    </div>
  </div>
</template>
<script>
import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';

export default {
  components:{
    ProductDetailsHeader
  },
  name: "error-page",
  data(){
    return{
      msg:"",
      msgError:"",
      backUrl:"",
    };
},
  mounted(){
    this.msg=this.$route.query.msg;
    this.backUrl=this.$route.query.backUrl

  },
  methods:{
    goBack()
    {
        if(this.backUrl=="New-Journey")
        this.$router.push({path: '/New-Journey'})
        else 
        this.$router.push({path: this.backUrl});

              
    }
  },

};
</script>
<style lang="scss">
@import "../../assets/scss/components/Loaders.scss";
</style>
