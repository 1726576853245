<template lang="">
    <div>
       
        <SingleQuote/>
    </div>
</template>
<script>

import SingleQuote from '@/components/selectCard/SingleQuote.vue';

export default {
    components:{
        SingleQuote,
        
    }
}
</script>
<style lang="">
    
</style>