<template lang="">
   <ProductDetailsHeader/>
    <div class="thank-you">
      <div class="text-center">
        <img src="/images/complete.gif" class="m-auto img-fluid mb-3" width="30%"   />
        <h4 class=" text-center">An email has been sent successfully to the  customer's register <br/> 
            email Id -  {{obfuscateEmail(email)}} and  SMS on mobile number {{obfuscatePhoneNumber(mobileno)}}</h4>
        <p class="text-center">Customer has to review and submit  the proposal along with  making a payment.</p>
      </div>
    </div> 
  </template>
  <script>
import { email } from '@vuelidate/validators';
import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';

  export default {
    components:{
      ProductDetailsHeader
    },
  data(){
    return{
      mobileno:'',
      email:'',
      user_profile:{}
    }
  },
  mounted(){
      this.user_profile=JSON.parse(sessionStorage.getItem("filledData"));
      this.email=this.user_profile.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.email.input
      this.mobileno=this.user_profile.fieldGroups.proposerDetails.fieldGroups.personalDetails.fields.mobileNumber.input
  },
  methods:{
    obfuscateEmail(email) {
      if(!email){
        return;
      }
      const [username, domain] = email.split('@');
    const domainParts = domain.split('.');
    
    if (username.length <= 3) {
        return `${username[0]}***@${domain}`;
    }

    const visibleChars = 2; // Number of visible characters from the start of the username
    const obfuscatedUsername = username.slice(0, visibleChars) + '*'.repeat(username.length - visibleChars);
    
    return `${obfuscatedUsername}@${domain}`;
},
obfuscatePhoneNumber(phoneNumber) {
  if(!phoneNumber){
        return;
      }
    const lastDigit = phoneNumber.slice(7);
    const hideLength = 7;
    const obfuscatedMiddle = '*'.repeat(hideLength);
    
    return `${obfuscatedMiddle}${lastDigit}`;
}
  }
  };
  </script>
  <style lang="scss">
 @import "../../assets/scss/components/Loaders.scss";
  </style>
  