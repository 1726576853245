<template>

  <section class="common-for-all" :class="{'dark': theme === 'dark'}">
    <div class="container">
      <div class="row justify-content-center">
        <div class="">
          <router-view v-slot="{ Component }">
            <transition mode="out-in" name="fadeIn">
              <component :is="Component"/>
            </transition>
            </router-view>
        </div>
      </div>
    </div>
  </section>
  <innerFooter />
  
</template>
<script>
import innerFooter from "@/components/footer/innerFooter.vue";
import { getDistributer, hasDarkMode } from './mixins/common.js';

export default {
  name: "app",
  components: {
    innerFooter,
    
  },
  mounted(){
    if (this.theme) {
      document.body.className = this.theme;
    }
    if(hasDarkMode() && getDistributer()["startWithDark"]){
      this.$store.commit("setTheme", 'dark');
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    }
  },
  watch: {
    theme(newTheme) {
      if (newTheme) {
        document.body.className = newTheme;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./assets/scss/distributorVariables/common";
.fadeIn-enter-from,
.fadeIn-leave-to{
  opacity: 0;
}
.fadeIn-enter-active,
.fadeIn-leave-active{
  transition: opacity 0.4s ease-in-out;
}
</style>
