<template lang="">
  <div class="vehicleDetails">
    <div class="commoncard">
      <form>
        <div class="form-group">
          <input
          :class="{ 'errorField': state.formFields.engineNumber.errorMessage }"
            type="text"
            class="form-control"
            id="registration-number"
            placeholder="Engine Number"
            maxlength="14"
            v-model="state.formFields.engineNumber.value"
            @blur="checkFieldsError('engineNumber')"
          />
          <span class="errorDefault" v-if="state.formFields.engineNumber.errorMessage">{{ state.formFields.engineNumber.errorMessage }}</span>

          <input
            type="text"
            :class="{ 'errorField': state.formFields.chasisNumber.errorMessage }"
            class="form-control mt-3"
            id="mob-number"
            placeholder="Chassis Number"
            maxlength="10"
            v-model="state.formFields.chasisNumber.value"
            @blur="checkFieldsError('chasisNumber')"
          />
          <span class="errorDefault" v-if="state.formFields.chasisNumber.errorMessage">{{ state.formFields.chasisNumber.errorMessage }}</span>
          <label class="form-check-label mt-2" for="flexSwitchCheckChecked"
            >Is your vehicle taken on loan?</label>
          <div class="form-check form-switch mt-2">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              v-model="state.formFields.isLoanTaken"
              :disabled="state.formFields.financier.value != 0"
            />
            <label class="form-check-label mt-2 ml-4" for="flexSwitchCheckChecked"
              >{{ state.formFields.isLoanTaken ? 'Yes' : 'No' }}</label>
          </div>

          <h4 class="mt-3">Financier</h4>

          <div class="manage-selects my-3">
            <div class="">
              <select class="form-select" aria-label="SBI Auto Loan" v-model="state.formFields.financier.value"
              @blur="checkFieldsError('financier')" :class="{ 'errorField': state.formFields.financier.errorMessage }" :disabled="state.formFields.isLoanTaken">
                <option selected value="0">SBI Auto Loan</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <span class="errorDefault" v-if="state.formFields.financier.errorMessage">{{ state.formFields.financier.errorMessage }}</span>
            </div>
          </div>
          <div class="d-flex gap-3 mt-3">
            <button class="backbutton">Back</button>
            <button class="btn commonButton" :disabled="checkButtonState">Proceed</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, watch, computed } from 'vue'
export default {
  setup() {
    const state = reactive({
      formFields: {
        engineNumber: {
          value: "",
          errorText: "Engine Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        chasisNumber: {
          value: "",
          errorText: "Chasis Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        isLoanTaken: false,
        financier: {
          value: "0",
          errorText: "Financier is Required",
          errorMessage: "",
          stepsMandatory: true
        },
      }
    })

    onMounted(() => {
      state.formFields.isLoanTaken = false;
    })

    function checkFieldsError(fieldName) {
      if (!state.formFields[fieldName].value || state.formFields[fieldName].value == 0) {
        state.formFields[fieldName].errorMessage = state.formFields[fieldName].errorText;
      } else {
        state.formFields[fieldName].errorMessage = ""
      }
    }

    const checkButtonState = computed(() => {
      let isDisabled = false;
      Object.keys(state.formFields).forEach(element => {
        if (!state.formFields[element].value || state.formFields[element].errorMessage) {
          isDisabled = true;
        }
      });
      // if (!state.formFields.agreeToConditions.value) {
      //   isDisabled = true;
      // }
      return isDisabled;
    });

    watch(() => state.formFields.isLoanTaken, (loanTaken) => {
      if (loanTaken) {
        state.formFields.financier.errorMessage = "";
      }
    });

    return {
      state,
      checkFieldsError,
      checkButtonState
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
</style>
