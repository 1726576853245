<template lang="">
    <div>
        <errorPage/>
    </div>
</template>
<script>
import ErrorPage from '@/components/pageLoaders/errorPage.vue';

export default {
    components:{
        ErrorPage
    }
}
</script>
<style lang="">
    
</style>