<template>
  <div class="select-vahicles">
    <section class="row justify-content-center" >
      <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8" >
        <h1 class="main-header">Insure and Secure Your Vehicle</h1>
        <p class="subheading">Protect your journey with motor insurance</p>
            <SelectCard />
      </div>
    </section>
  </div>
</template>

<script>
import SelectCard from "../selectCard/SelectCard.vue";

export default {
  name: "SelectVehicle",
  components: {
    SelectCard,
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-vahicle.scss";
</style>
