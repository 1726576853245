<template>
  <div class="select-card mt-3">
    <div class="row justify-content-center">
      <div class="col-md-5 col-12" v-for="item in items" :key="item.heading">
        <router-link to="/New-Journey">
          <div class="card mt-3" @click="captureProductType(item.motorProductType)" :style="cardStyle(item)">
            <div class="cardbackground" >
              <div class="d-flex justify-content-between align-items-center">
                <h3>{{ item.heading }}</h3>
                <div>
                  <img src="../../assets/images/arrow.svg" alt="">
                </div>
              </div>
              <div class="offerDiv">{{ item.offer }}</div>
              <img class="cardimage" :class="item.className" :src="item.bgImage" alt="" />
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getLandingImgs } from "@/mixins/common";

export default {
  mixins: [getLandingImgs],
  mounted() {
    sessionStorage.removeItem("userSelection");
  },
  data() {
    return {
      items: [
        {
          heading: "Car Insurance",
          offer: "30% Off",
          bgImage: getLandingImgs()["CAR"],
          motorProductType: "4W-Pvt",
          bgColor: "linear-gradient(180deg, hsla(30, 99%, 73%, 1) 0%, hsla(48, 99%, 65%, 1) 100%)",
          className: "carImage"
        },
        {
          heading: "Bike Insurance",
          offer: "20% Off",
          bgImage: getLandingImgs()["BIKE"],
          motorProductType:"2W-Pvt",
          bgColor: "linear-gradient(180deg, hsla(133, 18%, 75%, 1) 0%, hsla(146, 20%, 79%, 1) 100%)",
          className: "bikeImage"
        },
      ]
    };
  },
  methods: {
    captureProductType(productType) {
      this.$store.commit("setMotorProductType", productType);
      this.goToNewJourneyPage();
    },
    bgImage(imageUrl) {
      return {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        borderRadius: "30px",
        backgroundPosition: "center",
        width: "100%",
        height: "200px", // Adjust the height as needed
      };
    },
    cardStyle(item) {
      return {
        background: item.bgColor,
        transition: "all 0.3s ease"
      };
    },
    goToNewJourneyPage() {
      this.$router.push("/New-Journey");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
</style>
