<template lang="">
    <div>
        <innerHeader/>
        <ProcessWizard/>

    </div>
</template>
<script>
import ProcessWizard from "@/components/formWizard/processWizard.vue";
import InnerHeader from "@/components/header/innerHeader.vue";

export default {
    components:{
        ProcessWizard,
        InnerHeader
    }
}
</script>
<style scoped lang="scss">
   @import "../assets/scss/components/form-wizard.scss";
</style>