<template>
    <div class="container">
        <div class="row filter-bg">
            <label for="">Please select Insurer: </label>
            <select @change="choosePlanType" class="selectfilter" v-model="selectedInsurer">
                <option v-for="(item, ind) in listOfRmInsurer" :key="ind" v-bind:value="item.value">
                    {{ item.name }}
                </option>
            </select>
        </div>

        <div class="card">
            <h3>Upload File</h3>
            <div class="drop_box">
                <div>
                    <h4 v-if="!fileName">Select File here</h4>
                    <h4 v-else>{{ fileName }}</h4>
                </div>
                <p v-if="!fileName">Files Supported: CSV</p>
                <input type="file" hidden accept=".csv" id="fileID" ref="fileInput" @change="handleFileChange">
                <button class="btn" @click="triggerFileInput" v-if="!uploading" :disabled="selectedInsurer === 'select'"
                    :class="selectedInsurer === 'select' && 'disabled'">Choose File</button>
                <div v-if="fileName" class="upload-handler">
                    <button class="btn" @click="uploadFile"
                        :class="(uploading || selectedInsurer === 'select') && 'disabled'"
                        :disabled="(uploading || selectedInsurer === 'select')">Upload</button>
                </div>
                <div v-if="uploading" class="progress-bar">
                    <div class="progress" :style="{ width: progress + '%' }"></div>
                </div>
                <span v-if="status" class="success">{{ status }}</span>
                <span v-if="errorDisplayMessage" class="error-text">{{ errorDisplayMessage }}</span>
                <span v-if="!uploading" class="error-text"> Old data will get replaced with New data uploaded.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { getRmDataUploadInsurer } from "@/mixins/common";
export default {
    name: 'fileUpload',
    data() {
        return {
            fileName: '',
            status: "",
            errorDisplayMessage: '',
            uploading: false,
            progress: 0,
            selectedInsurer: 'select',
        };
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.fileName = file.name;
            }
        },
        async uploadFile() {
            try {
                if (!this.fileName) {
                    alert('Please select a file first.');
                    return;
                }
                this.uploading = true;
                const fileInput = this.$refs.fileInput;
                const file = fileInput.files[0];
                const formData = new FormData();
                formData.append('file', file);
                const headers = {};
                const manufacturer_id = this.selectedInsurer;
                const qData = [manufacturer_id];
                const response = await this.backendApi('fileUpload', formData, headers, "post", qData);
                for (let i = 0; i < 70; i += 0.5) {
                    this.progress += i;
                }
                setTimeout(() => {
                    if (response.data === null) {
                        this.errorDisplayMessage = 'Failed to upload';
                        this.fileName = '';
                        this.$refs.fileInput.value = '';
                        this.uploading = false;
                        this.progress = 100;
                    } else if (response.data.errors.length > 0 || response == "error" || response.status == "error") {
                        this.errorDisplayMessage = response.data.errors[0].errorDisplayMessage;
                        this.fileName = '';
                        this.$refs.fileInput.value = '';
                        this.uploading = false;
                        this.progress = 100;
                    } else if (response.data.data.uploadStatus) {
                        this.status = response.data.data.uploadStatus;
                        this.fileName = '';
                        this.$refs.fileInput.value = '';
                        this.uploading = false;
                        this.progress = 100;
                    }
                }, 5000)
            } catch (error) {
                console.log(error);
                this.fileName = '';
                this.$refs.fileInput.value = '';
                this.uploading = false;
                this.progress = 100;
            }
        }
    },
    computed: {
        listOfRmInsurer() {
            return getRmDataUploadInsurer();
        }
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/fileUpload.scss";
</style>
