<template lang="">
  <innerHeader/>
    <div class="commonmargin">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 col-md-4 col-xl-4 text-center kycform">
          <img src="/images/successGif.gif" />
          <h1 class="headings text-success my-3">Document Uploaded Successfully</h1>
         
         
        </div>
      </div>
    </div>
  </template>
  <script>
 import innerHeader from "@/components/header/innerHeader.vue";
export default {
  components:{
    innerHeader
  }
}
  </script>
  <style scoped lang="scss">
  @import "../../assets/scss/components/Kyc.scss";
  </style>
  