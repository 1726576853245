<template lang="">
    <div class="footer d-flex justify-content-between" :class="{'dark': theme === 'dark'}">
       <div class="d-flex gap-3 w-100">
        <a href="https://1silverbullet.tech/terms" target="_blank" class="poilices">Terms & Conditions</a> |
        <a href="https://1silverbullet.tech/disclaimer" class="poilices" target="_blank">Disclaimer</a>
       </div>
      
        <p class="p-0">© Silverbullet Platforms Private Limited. All rights reserved</p>
    </div>
</template>
<script>
export default {
    computed: {
        theme() {
            return this.$store.state.theme;
        }
    }
}
</script>
<style scoped lang="scss">

   
    
    @import "../../assets/scss/components/inner-footer.scss"
</style>