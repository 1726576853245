<template lang="">
    <div>
        <innerHeader/>
        <kycForm/>
    </div>
</template>
<script>
import innerHeader from "../components/header/innerHeader.vue"
import kycForm from "../components/ckyc/kycForm.vue"
export default {
    components:{
        kycForm,
        innerHeader
    }
}
</script>
<style lang="">
    
</style>