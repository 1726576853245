<template lang="">
    <div>
        <innerHeaderDiy/>
        <PaymentSuccess/>
    </div>
</template>
<script>
import PaymentSuccess from '@/components/pageLoaders/paymentSuccess.vue';
import innerHeaderDiy from "@/components/header/innerHeaderDiy";


export default {
    components:{
        PaymentSuccess,
        innerHeaderDiy
    }
}
</script>
<style lang="">
    
</style>