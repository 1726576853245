<template lang="">
  <innerHeader/>
  <div class="policyDetails">
    <div class="commoncard">
      <h4 class="">Own Damage Detail</h4>
      <form>
        <div class="form-group">
          <select class="form-select" aria-label="Insurer" :class="{ 'errorField': state.formFields.insurer.errorMessage }" v-model="state.formFields.insurer.value" @blur="checkFieldsError('insurer')">
            <option selected disabled value="0">Insurer</option>
            <option value="1">Insurer One</option>
            <option value="2">Insurer Two</option>
            <option value="3">Insurer Three</option>
          </select>
          <span class="errorDefault" v-if="state.formFields.insurer.errorMessage">{{ state.formFields.insurer.errorMessage }}</span>
          
          <input
            type="text"
            class="form-control mt-3"
            id="Policy Expiry Date"
            onfocus="(this.type='date')"
            placeholder="Policy Expiry Date"
            :class="{ 'errorField': state.formFields.policyExpiryDate.errorMessage }"
            v-model="state.formFields.policyExpiryDate.value" 
            @blur="checkFieldsError('policyExpiryDate')"
          />
          <span class="errorDefault" v-if="state.formFields.policyExpiryDate.errorMessage">{{ state.formFields.policyExpiryDate.errorMessage }}</span>

          <input
            type="number"
            class="form-control mt-3"
            id="mob-number"
            placeholder="Policy Number"
            :class="{ 'errorField': state.formFields.policyNumber.errorMessage }"
            v-model="state.formFields.policyNumber.value" 
            @blur="checkFieldsError('policyNumber')"
            />
            <span class="errorDefault" v-if="state.formFields.policyNumber.errorMessage">{{ state.formFields.policyNumber.errorMessage }}</span>
          <hr />

          <h4 class="mt-3">Third Party Detail</h4>

          <select class="form-select" aria-label="Insurer" :class="{ 'errorField': state.formFields.tpInsurer.errorMessage }"
            v-model="state.formFields.tpInsurer.value" 
            @blur="checkFieldsError('tpInsurer')">
            <option selected disabled value="0">Insurer</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <span class="errorDefault" v-if="state.formFields.tpInsurer.errorMessage">{{ state.formFields.tpInsurer.errorMessage }}</span>

          <input
            type="text"
            class="form-control mt-3"
            id="policyExpiryDate"
            maxlength="14"
            placeholder="Policy Expiry Date"
            onfocus="(this.type='date')"
            :class="{ 'errorField': state.formFields.tpPolicyExpiryDate.errorMessage }"
            v-model="state.formFields.tpPolicyExpiryDate.value" 
            @blur="checkFieldsError('tpPolicyExpiryDate')"
          />
          <span class="errorDefault" v-if="state.formFields.tpPolicyExpiryDate.errorMessage">{{ state.formFields.tpPolicyExpiryDate.errorMessage }}</span>

          <input
            type="number"
            class="form-control mt-3"
            id="registration-number"
            placeholder="Policy Number"
            maxlength="14"
            :class="{ 'errorField': state.formFields.tpPolicyNumber.errorMessage }"
            v-model="state.formFields.tpPolicyNumber.value" 
            @blur="checkFieldsError('tpPolicyNumber')"
          />
          <span class="errorDefault" v-if="state.formFields.tpPolicyNumber.errorMessage">{{ state.formFields.tpPolicyNumber.errorMessage }}</span>

          <div class="form-check conditioncheck mt-2 d-flex align-items-top">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckIndeterminate"
              v-model="state.formFields.agreeToConditions.value"
            />
            <label class="form-check-label" for="flexCheckIndeterminate">
              <p class="ml-2 mb-0">I agree to the terms & conditions and confirm: my car is not a
              commercial vehicle and my car has a valid PUC certificate.</p>
            </label>
          </div>
          <div class="row mt-3">
            <div class="col-md-4">
              <button class="backbutton">Back</button>
            </div>
            <div class="col-md-8">
                <button class="btn commonButton" :disabled="checkButtonState" @click="proceedToConfirmPayment">Proceed</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import innerHeader from "../header/innerHeader.vue"
export default {
  components:{
    innerHeader
  },
  setup() {
    const router = useRouter();
    let state = reactive({
      formFields: {
        insurer: {
          value: "0",
          errorText: "Insurer is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        policyExpiryDate: {
          value: "",
          errorText: "Policy Expiry Date is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        policyNumber: {
          value: "",
          errorText: "Policy Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        tpInsurer: {
          value: "0",
          errorText: "Insurer is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        tpPolicyExpiryDate: {
          value: "",
          errorText: "Policy Expiry Date is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        tpPolicyNumber: {
          value: "",
          errorText: "Policy Number is Required",
          errorMessage: "",
          stepsMandatory: true
        },
        agreeToConditions: {
          value: "",
          errorText: "",
          errorMessage: "",
          stepsMandatory: true
        },
      }
    })

    function checkFieldsError(fieldName) {
      if (!state.formFields[fieldName].value || state.formFields[fieldName].value == 0) {
        state.formFields[fieldName].errorMessage = state.formFields[fieldName].errorText;
      } else {
        state.formFields[fieldName].errorMessage = ""
      }
    }

    const checkButtonState = computed(() => {
      let isDisabled = false;
      Object.keys(state.formFields).forEach(element => {
        if (!state.formFields[element].value || state.formFields[element].errorMessage) {
          isDisabled = true; 
        }
      });
      if (!state.formFields.agreeToConditions.value) {
        isDisabled = true;
      }
      return isDisabled;
    });

    function proceedToConfirmPayment(){
      if(!checkButtonState.value){
        router.push("/confirm-payment")
      }
    }
    return {
      state,
      checkFieldsError,
      checkButtonState,
      proceedToConfirmPayment
    }
  }



};
</script>
<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
</style>
