import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/scss/distributorVariables/variable.scss"
import store from './store/index';
import 'bootstrap'
import backendApi from './apis/axios-backend'
import "vue3-loading-skeleton/dist/style.css";
import VTooltip from 'v-tooltip';
const myMixin = {
  data() {
    return {
        motorProductType: this.$store.state.motorProductType
    };
},
  methods: {
    backendApi
}
   
   
};
var app=createApp(App);
     app.use(store)
        .use(router)
        .mixin(myMixin)
        .use(VTooltip);
  app.mount("#app");


