<template lang="">
    <div>
        <innerHeader/>
       <multiQuote/>
      
    </div>
</template>
<script>
import multiQuote from "@/components/multiQuote/multiQuote.vue"
import innerHeader from "@/components/header/innerHeader.vue";

export default {
    components:{
        multiQuote,
        innerHeader
    }
}
</script>
<style lang="scss">
    :focus-visible {
  outline: none !important;
}
</style>