<template lang="">
  <ProductDetailsHeader/>
    <div class="thank-you">
      <div class="text-center">
        
        <h4 class=" text-center">Thank You for sharing your details.</h4>
        <p class="text-center">As inspection is required for the vehicle, <br/> kindly complete the inspection</p>
        
        <img src="/images/thankyou.gif" class="m-auto" />

      <div class="row justify-content-center">
        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
          <div class="headingcards" >
            <div class="mb-3">
              <p class="alignment" >Journey ID</p>
            <h3 class="alignment" >{{journeyId}}</h3>
            </div>
            <div v-if="inspectionLink" class="mb-3">
              <p class="alignment" >Inspection Link</p>
            <a :href=inspectionLink class="alignment" > {{inspectionLink}}</a>
            </div>
            <div v-if="inspectionvalidTill" class="mb-3"> 
              <p class="alignment"  >Inspection Validity</p>
            <h3 class="alignment" >{{inspectionvalidTill}}</h3>
            </div>
        </div>
        </div>
      </div>
        
      </div>
    </div> 
  </template>
  <script>
import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';

export default {
    components:{
      ProductDetailsHeader
    },
  data(){
    return{
    policyNo: "",
    journeyId:"",
    inspectionLink:"",
    inspectionvalidTill:"",

  };
},
  mounted(){
    if(sessionStorage.getItem("inspectionLink")) {
      this.inspectionLink = JSON.parse(sessionStorage.getItem("inspectionLink"));
    }
    if(sessionStorage.getItem("inspectionvalidTill")) {
      this.inspectionvalidTill = JSON.parse(sessionStorage.getItem("inspectionvalidTill"));
    }
    if(sessionStorage.getItem("journey_id")){
      this.journeyId = JSON.parse(sessionStorage.getItem("journey_id"));
    }
  },
  methods:{

  },

  };

  </script>
  <style lang="scss">
 @import "../../assets/scss/components/Loaders.scss";
  </style>
  