<template lang="">
  <ProductDetailsHeader/>
    <div class="thank-you" >
      <div class="text-center">
        <img src="/images/paymentPending.gif" class="m-auto bg-black" />
        <h4 class=" text-center ">Payment Pending</h4>
        <!-- <p class="text-center">Please wait</p> -->
        
       

      
        
      </div>
    </div> 
  </template>
  <script>
import ProductDetailsHeader from '../productDetailsHeader/ProductDetailsHeader.vue';

  export default {
  components:{
    ProductDetailsHeader
  }
  };
  </script>
  <style lang="scss">
 @import "../../assets/scss/components/Loaders.scss";
  </style>
  