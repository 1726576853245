
/**
 * A class that generates a secret string of random characters.
 * @constructor
 * @param {number} [secret_bit=32] - The length of the secret string to generate.
 * @property {number} secret_bit - The length of the secret string to generate.
 * @property {string} random_string - The string of characters to use when generating the secret.
 */
export const generate_secret_key=()=>{
    var secret_bit=32
    var random_string = "abcdefghijklmnopqrstuvwxyz1234567890"
    random_string = random_string.split("");
    var secret=""; 
    for(var i=0;i<secret_bit;i++){
        secret += random_string[Math.floor(Math.random()*random_string.length)];
    }
    console.log("secret",secret)
    return secret; //Will return a 32 bit "hash"
}
// class SecretGenerator{
//     constructor(secret_bit=32) {
//         this.secret_bit=secret_bit
//         this.random_string = "abcdefghijklmnopqrstuvwxyz1234567890"
//         console.log(" this.random_string", this.random_string)
//     }
//     /**
//      * Generates a secret key by selecting random characters from a given string.
//      * @returns {string} - The generated secret key.
//      */
//     generate_secret_key() {
//         const random_string = this.random_string.split("");
//         var secret=""; 
//         for(var i=0;i<this.secret_bit;i++){
//             secret += random_string[Math.floor(Math.random()*random_string.length)];
//         }
//         console.log("secret",secret)
//         return secret; //Will return a 32 bit "hash"
//     }
// }