<template lang="">
  <div class="loaders">
    <div>
        <p class="text-center">{{msg}}</p>
    <div class="text-center">
      <img src="/images/pageloader.gif"  />
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading-page",
  props: {
    msg: String,
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/components/Loaders.scss";
</style>
