<template lang="">
  <innerHeader/>
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8">
      <div class="formWizard row proccess-wizard">
        <div class="col-md-6 formobileview">
          <div class="position-sticky" style="top: 5rem">
            <h3 class="processheading">
              Confirm & Proceed
            </h3>
            <div class="formobileview">
              <div
              class="single-quote headingcards d-flex justify-content-between align-items-center">
              <div>
                <h4 class="mb-0">MH12UP7358</h4>
                <p class="mb-0">
                  DL-01 . Hyndai . Grandi10 . Disel . Prime Era T CRDI (1120 CC)
                  . Jan 2015
                </p>
              </div>
              <div>
                <a
                  href=""
                  class="editform"
                  data-bs-toggle="modal"
                  data-bs-target="#editDetailsModal"
                  >
                  
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"></path></svg>
                  edit
                </a>
              </div>
            </div>

            <ul
              class="nav nav-tabs d-block m-3 justify-content-left border-0"
              id="myTab"
              role="tablist"
            >
              <li
                v-for="(step, index) in steps"
                :key="index"
                class="nav-item completed"
                role="presentation"
                :data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="step.title"
                
              >
                <a
                  :class="[
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    'gap-2',
                    { active: activeTab === index },
                  ]"
                  @click="selectTab(index)"
                  role="tab"
                  :aria-controls="'step' + (index + 1)"
                  :aria-selected="activeTab === index"
                  style="width: 100%"
                >
                  <div
                    class="d-flex justify-content-between"
                    style="width: 100%"
                  >
                    <div class="d-flex align-items-center gap-1">
                      <i class="activeicon">{{ step.icon }}</i>
                <div class="checkincon">
                  <img src="../assets/images/check.svg"  />
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                    />
                  </svg> -->
                </div>
                      <p class="mb-0" v-html="step.subcontent"></p>
                    </div>
                    <a
                      href=""
                      class="editform"
                      data-toggle="modal"
                      data-target="#savingseditprofile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"
                        />
                      </svg>
                      edit
                    </a>
                  </div>
                </a>
              </li>
            </ul>
            </div>
          </div>
        </div>

        <div class="tab-content  col-md-6" id="myTabContent">
          <div
            v-for="(step, index) in steps"
            :key="'content-' + index"
            :class="[
              'tab-pane',
              'fade',
              { show: activeTab === index, active: activeTab === index },
            ]"
            role="tabpanel"
            :id="'step' + (index + 1)"
            :aria-labelledby="'step' + (index + 1) + '-tab'"
          >
            <div>
              <component :is="step.SelectComponent" />
            </div>
            <div
              class="d-flex mt-3 justify-content-between"
              :class="{
                'justify-content-between':
                  index !== 0 && index !== steps.length - 1,
                'justify-content-end': index === 0,
                'justify-content-between': index === steps.length - 1,
              }"
            >
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <editDetailsModal/>
</template>
<script>
import { ref } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import SingleQuote from "@/components/selectCard/SingleQuote.vue";
import InnerHeader from "@/components/header/innerHeader.vue";


export default {
  name: "formWizard",
  components: {
    
    SingleQuote,
    InnerHeader
  },
  setup() {
    const activeTab = ref(0);
    const steps = ref([
    {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>City</b>and  <b>RTO</b>",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: " Choose <b>Car Make</b>",
        SelectComponent: "SingleQuote",
      },
      
      {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>Car Model</b> ",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>Car Fuel Type</b> ",
        SelectComponent: "SingleQuote",
      },
      
      {
        title: "Step 1",
        icon: "2",
        subcontent: " Choose  <b>Car Variant</b> ",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: "<b>Additional</b> Details ",
        SelectComponent: "SingleQuote",
      },
      
    ]);

    const nextStep = () => {
      if (activeTab.value < steps.value.length - 1) {
        activeTab.value += 1;
      }
    };

    const previousStep = () => {
      if (activeTab.value > 0) {
        activeTab.value -= 1;
      }
    };

    const selectTab = (index) => {
      activeTab.value = index;
    };

    return {
      activeTab,
      steps,
      nextStep,
      previousStep,
      selectTab,
    };
  },
  methods:{
    completed(index) {
      this.activeTab = index;
    },
    isCompleted(index) {
      return index < this.activeTab;
    },
  }
};
</script>
<style scoped lang="scss">
@import "../../src/assets/scss/components/form-wizard.scss"

</style>
