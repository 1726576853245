<template lang="">
    <div>
        <innerHeader/>
        <kycSuccess/>
    </div>
</template>
<script>
import InnerHeader from "@/components/header/innerHeader.vue";
import kycSuccess from "../components/ckyc/kycSuccess.vue"
export default {
    components:{
        kycSuccess,
        InnerHeader
    }
}
</script>
<style lang="">
    
</style>