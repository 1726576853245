<template lang="">
    <div>
        <innerHeader/>
        <completeThankyou/>

    </div>
</template>
<script>
import completeThankyou from "@/components/pageLoaders/completeThankyou.vue";
import InnerHeader from "@/components/header/innerHeader.vue";

export default {
    components:{
        completeThankyou,
        InnerHeader
    }
}
</script>
<style scoped lang="scss">
   @import "../assets/scss/components/form-wizard.scss";
</style>