export const getRequestData = (api, data) => {
  //console.log('getRequestData', api)
  return {
    body: data,
    url: apis[api]["url"],
    baseURL:
      apis[api]["baseURL"] == undefined
        ? process.env.VUE_APP_URL
        : process.env["VUE_APP_URL_" + apis[api]["baseURL"]],
    source:
      apis[api]["baseURL"] == undefined ? "DEFAULT" : apis[api]["baseURL"],
  };
};

const apis = {
  getConfigData: {
    url: "/v2/motor/configuration?distributor_id=param1",
    baseURL: "RMD",
  },
  vehicleSearch: {
    url: "/v2/motor/looker",
    baseURL: "RMD",
  },
  //get OTP
  getOtpSend: {
    url: "/v2/motor/otp/send",
    baseURL: "RMD"
  },
  downloadPolicy: {
    url: "/v2/motor/receipt/download?journey_id=param1",
    baseURL: "RMD",
  },
  getPolicyStatus: {
    url: "/v2/motor/proposal/status?journey_id=param1",
    baseURL: "RMD",
  },
  getOtpValidate: {
    url: "/v2/motor/otp/validate",
    baseURL: "RMD"
  },
  getPaymentUrlPoll: {
    url: "/v2/motor/proposal/draft?journey_id=param1&stage=param2",
    baseURL: "RMD"
  },
  saveProposal: {
    url: "/v2/motor/proposal/draft",
    baseURL: "RMD"
  },
  //getProposalDetails
  getProposalDetails: {
    url: "/v2/motor/proposal/draft?journey_id=param1&stage=param2",
    baseURL: "RMD"
  },
  //getKyc Details
  getCkyc: {
    url: "/v2/motor/ckyc",
    baseURL: "RMD"
  },
  getProposalWithStage: {
    url: `/v2/motor/proposal/draft?journey_id=param1&stage=param2`,
    baseURL: "RMD",
  },
  getRmDetails: {
    url: "/v2/motor/user",
    baseURL: "RMD",
  },
  generateToken: {
    url: "/v2/token?tokenType=SESSION",
    baseURL: "RMD",
  },
  getDashboardData:{
    url: "/v2/motor/dashboard?pageSize=param1&pageNumber=param2&columnName=param3&filterText=param4&startDate=param5&endDate=param6",
    baseURL: "RMD"
  },
  generateTokenOverride: {
    url: "/v2/token?tokenType=SESSION&override=true",
    baseURL: "RMD",
  },
  refreshToken: {
    url: "/v2/token/refresh?tokenType=SESSION",
    baseURL: "RMD",
  },
  getPincodeInfo: {
    url: "/v2/motor/zipcode?zipcode=param1",
    baseURL: "RMD",
  },
  //multi quote
  quotationApi: {
    url: "/v2/motor/quote",
    baseURL: "RMD",
  },

  //Multi quote poll
  quotationPoll: {
    url: "/v2/motor/quote/poll?request_id=param1",
    baseURL: "RMD",
  },
  // getCkyc: {
  //   url: "/v2/motor/ckyc",
  //   baseURL: "RMD"
  // },

  //get proposal api
  getProposal: {
    url: "/v2/motor/proposal?product_id=param1&manufacturer_id=param2&version=param3",
    baseURL: "RMD",
  },
  getSPDetailsOneSB: {
    url: "/v2/motor/spDetails?manufacturerId=param1",
    baseURL: "RMD"
  },

  // getProductSelection: {
  //   url: "/v2/lifeterm/product/param1",
  //   baseURL: "RMD"
  // },
  setProductSelection: {
    url: "/v2/motor/product/param1?multi_quote_response_id=param2",
    baseURL: "RMD"
  },
  // customerInfo:{
  //   url:"/v2/lifeterm/customerInfo/param1",
  //   baseURL: "RMD"
  // },
  // updateInformation:{
  //   url:"/v2/lifeterm/updateInformation",
  //   baseURL: "RMD"
  // },
  // userLogin:{
  //   url: "/v2/lifeterm/login",
  //   baseURL: "RMD"
  // },
  // //Store payments details in paymentDetails table
  // savePaymentData:{
  //   url: "/v2/lifeterm/paymentDetails",
  //   baseURL: "RMD"
  // },
  // getPaymentData:{
  //   url: "/v2/lifeterm/paymentDetails?proposal_ui_id=param1",
  //   baseURL: "RMD"
  // },

  // getPaymentUrlPoll: {
  //   url: "/v2/lifeterm/proposal/draft?journey_id=param1&stage=param2",
  //   baseURL: "RMD"
  // },
  saveProposal: {
    url: "/v2/motor/proposal/draft",
    baseURL: "RMD"
  },
  // getProposalDetails: {
  //   url: "/v2/lifeterm/proposal/draft?journey_id=param1&stage=param2",
  //   baseURL: "RMD"
  // },
  // getQuoteDetails: {
  //   url: "/v2/lifeterm/proposal/draft?journey_id=param1",
  //   baseURL: "RMD"
  // },
  // getQuoteResponseDetails: {
  //   url: "/v2/lifeterm/quoteResponse?UiQuoteId=param1",
  //   baseURL: "RMD"
  // },

  // getPincodeInfo: {
  //   url: "/v2/lifeterm/zipcode?zipcode=param1",
  //   baseURL: "RMD"
  // },
  // getProposal: {
  //   url: "/v2/lifeterm/proposal?product_id=param1&manufacturer_id=param2&version=param3",
  //   baseURL: "RMD"
  // },
  // validateProposal:{
  //   url: "/v2/lifeterm/proposal/validate",
  //   baseURL: "RMD"
  // },
  // sendMail: {
  //   url: "/v2/mail/send",
  //   baseURL: "RMD"
  // },
  ProposalStatus: {
    url: "/v2/motor/proposal/draft",
    baseURL: "RMD"
  },
  // getProposalStatus: {
  //   url: "/v2/lifeterm/journey?journey_id=param1",
  //   baseURL: "RMD"
  // },

  // saveProposalResponse: {
  //   url: "/v2/lifeterm/proposalResponse",
  //   baseURL: "RMD"
  // },
  // quotationApi: {
  //   url: "/v2/lifeterm/quote",
  //   baseURL: "RMD"
  // },
  // proposalVersionApi: {
  //   url: "/v2/lifeterm/proposal/submit",
  //   baseURL: "RMD"
  // },
  // proposalPollApi: {
  //   url: "/v2/lifeterm/proposal/poll?product_id=param1&manufacturer_id=param2&request_id=param3",
  //   baseURL: "RMD"
  // },
  // getProductUiDataApi: {
  //   url: "/v2/lifeterm/product/information?manufacturer_id=param1&product_id=param2",
  //   baseURL: "RMD"
  // },
  // paymentUrlApi: {
  //   url: "/v2/lifeterm/payment",
  //   baseURL: "RMD"
  // },
  // quotationPoll: {
  //   url: "/v2/lifeterm/quote/poll?request_id=param1",
  //   baseURL: "RMD"
  // },

  sendEmailandSMS: {
    url: "/v2/motor/communication",
    baseURL: "RMD"
  },
  getCustomerDetails: {
    url: "/v2/motor/customer",
    baseURL: "RMD",
  }
};
