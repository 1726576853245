
import forge from "node-forge";
/**
 * A class representing RSA-256 asymmetric encryption. 
 * The class contains a constructor that sets the secret key to "public_key.pem".
 */
const public_key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyyacBmYcd7KX3RRBfLnlZ1u8X2lh0lI5m4vxhIw1FaUHY3m/fVYZpndRXCLswFBLX32WwkA7K+JRdaeLrMu7UhkoKYXOQa3ZESfO0O6YPKtJl6mD+VIs2ggUW5rRTWfDOjTbnmPMJLlyTIQRCzhGpkvg0/dzDQnWIcDyCqgxe6n7WMSoiEqgoPasTNdM0xgpT1XGJD0aLIlzcOt1AKcVDVPRjvV+JJarkKq3llCTru2zFzg+PZmimzRK+O9Z7YmYcJJKqzR+XP2kU7vTRGVARUzpomuWWaTyB9ejKU/bOc9HwCLzd++63+ucaezBEOYcLDnUN8zFImtfM/tRxbONbQIDAQAB-----END PUBLIC KEY-----"

function read_public_key(){
    // var public_key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyyacBmYcd7KX3RRBfLnlZ1u8X2lh0lI5m4vxhIw1FaUHY3m/fVYZpndRXCLswFBLX32WwkA7K+JRdaeLrMu7UhkoKYXOQa3ZESfO0O6YPKtJl6mD+VIs2ggUW5rRTWfDOjTbnmPMJLlyTIQRCzhGpkvg0/dzDQnWIcDyCqgxe6n7WMSoiEqgoPasTNdM0xgpT1XGJD0aLIlzcOt1AKcVDVPRjvV+JJarkKq3llCTru2zFzg+PZmimzRK+O9Z7YmYcJJKqzR+XP2kU7vTRGVARUzpomuWWaTyB9ejKU/bOc9HwCLzd++63+ucaezBEOYcLDnUN8zFImtfM/tRxbONbQIDAQAB-----END PUBLIC KEY-----"
    return public_key
}
export const rsa_256_encrypt=(data)=>{
    var public_key = forge.pki.publicKeyFromPem(read_public_key());
    var encrypted_data = public_key.encrypt(data, "RSA-OAEP", {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf1.create()
    });
    return forge.util.encode64(encrypted_data);
}
// class RSA256AsymmetricEncryption{
//     constructor() {
//         this.secret_key="public_key.pem"
//         console.log("this.secret_key",this.secret_key)
//     }

//     read_public_key(){
//         // var xmlhttp = new XMLHttpRequest();
//         // xmlhttp.open("GET",this.secret_key,false);
//         // xmlhttp.send();
//         // return xmlhttp.responseText;
//         var public_key = "-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyyacBmYcd7KX3RRBfLnlZ1u8X2lh0lI5m4vxhIw1FaUHY3m/fVYZpndRXCLswFBLX32WwkA7K+JRdaeLrMu7UhkoKYXOQa3ZESfO0O6YPKtJl6mD+VIs2ggUW5rRTWfDOjTbnmPMJLlyTIQRCzhGpkvg0/dzDQnWIcDyCqgxe6n7WMSoiEqgoPasTNdM0xgpT1XGJD0aLIlzcOt1AKcVDVPRjvV+JJarkKq3llCTru2zFzg+PZmimzRK+O9Z7YmYcJJKqzR+XP2kU7vTRGVARUzpomuWWaTyB9ejKU/bOc9HwCLzd++63+ucaezBEOYcLDnUN8zFImtfM/tRxbONbQIDAQAB-----END PUBLIC KEY-----"
//         return public_key
//     }
    
//     /**
//      * Encrypts the given data using RSA-256 encryption with the public key stored in the object.
//      * @param {string} data - The data to be encrypted.
//      * @returns {string} The encrypted data encoded in base64.
//      */
//     rsa_256_encrypt(data){
//         var public_key = forge.pki.publicKeyFromPem(this.read_public_key());
//         var encrypted_data = public_key.encrypt(data, "RSA-OAEP", {
//             md: forge.md.sha256.create(),
//             mgf1: forge.mgf1.create()
//         });
//         return forge.util.encode64(encrypted_data);
//     }

// }