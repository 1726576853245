<template>
  <!-- <div
    class="modal varificationModal"
    tabindex="-1"
    role="dialog"
    id="editDetailsModal"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center"> -->
          <div class="modal-overlay varificationModal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content d-flex justify-content-between" >
              <div class="modal-header d-flex justify-content-between align-items-center w-100">
    <h5 class="modal-title mx-auto text-center">
        <span v-if="motorProductType === '4W-Pvt'">Edit Car Details</span>
        <span v-else-if="motorProductType === '2W-Pvt'">Edit Bike Details</span>
    </h5>
    <button class="editform-cross border-0 ml-auto" @click="closemodal"><span style="font-size: 22px;">&times;</span></button>
</div>
        <div class="modal-body">
          <div class="d-flex justify-content-between align-items-center mb-3 " >
            <div >
              <p class="text-left" style="text-align: left;">RTO</p>
              <h6> {{ this.userData?.rtoCode }} </h6>
            </div>
            
            <div>
              <button @click="navigateStep(2)" class="editform">
                <img src="../assets/images/edit.svg" alt="">
                </button>
            
            </div>
            
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3 ">
            <div >
              <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Make</p>
              <h6>{{this.userData?.carMake?.makeDescription}}</h6>
            </div>
            
            <div>
              <button @click="navigateStep(3)" class="editform">
                <img src="../assets/images/edit.svg" alt="">  
              </button>
             
            </div>
            
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3 ">
            <div >
              <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Model</p>
              <h6>{{this.userData?.model?.modelDesc}}</h6>
            </div>
            
            <div>
              <button @click="navigateStep(4)" class="editform">
                <img src="../assets/images/edit.svg" alt="">
              </button>
             
            </div>
            
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3 ">
            <div >
              <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Variant</p>
              <h6>{{this.userData?.variant?.variantDesc}}</h6>
            </div>
            
            <div>
              <button @click="navigateStep(5)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
              
            </div>
            
          </div>
          <div class="d-flex justify-content-between align-items-center mb-3 ">
            <div >
              <p class="text-left" style="text-align: left;">{{ checkCarOrBike() }} Fuel Type</p>
              <h6>{{ this.userData?.fuelType?.fuelType?.toUpperCase() }}</h6>

            </div>
            
            <div>
              <button @click="navigateStep(6)" class="editform"><img src="../assets/images/edit.svg" alt=""></button>
            </div>
            
          </div>

  
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  name: "editDetailsModal",
  components: {},
  methods: {
    closemodal(){
  this.$emit('close-Editmodal')
    },
    checkCarOrBike(){
                        return this.motorProductType === '4W-Pvt' ? 'Car' : this.motorProductType === '2W-Pvt' ? 'Bike' : null;
                },
    navigateStep(index){
      $('.modal-backdrop').removeClass('show');
      $('.modal-backdrop').remove();
      document.body.setAttribute('style', '');
      document.body.setAttribute('class', '');

      const userData = JSON.parse(sessionStorage.getItem('userData'));
      let trigger = index==2 ? 'rto' : index==3 ? 'carMake' : index==4 ? 'carModel' : index==5 ? 'carVariant' : null
      switch (trigger) {
        case 'rto':
          delete userData.carMake;
          delete userData.model;
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
        case 'carMake':
          delete userData.model;
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
        case 'carModel':
          delete userData.variant;
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
          case 'carVariant':
          delete userData.fuelType;
          delete userData.RegDate
          delete userData.MfgYear
          delete userData.OdDate
          delete userData.TpDate
          break;
        default:
          break;
      }
      sessionStorage.setItem('userData', JSON.stringify(userData));
      sessionStorage.setItem('edit',JSON.stringify(index));

      this.$router.push({ path: '/form-Wizard' });
    }


},
  data() {
    return {
      // items:[
      //   {
      //     RTO:"RTO",
      //     RTOName:"DL-01"
      //   },
      //   {
      //     RTO:"Car Make",
      //     RTOName:"Hyundai"
      //   },
      //   {
      //     RTO:"Car Model",
      //     RTOName:"Grand i10"
      //   },
      //   {
      //     RTO:"Car Fuel Type",
      //     RTOName:"Petrol"
      //   },
      //   {
      //     RTO:"Car Variant",
      //     RTOName:"Prime Era T CRDI (1120 CC)"
      //   }
      // ]
      userData:{},
      motorProductType:'',
    };
  },
  mounted(){
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.motorProductType=this.$store.state.motorProductType;
  }
};
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup {
    position: relative;
    background-color: #FFF;
    max-width: 410px;
    min-height: 220px;
    max-height: fit-content;
    overflow-y: auto;
    width: 90%;
    border-radius: 12px;
    padding: 48px 12px 12px;
}

#endclose {
    position: relative;
    left: 0;
}
.dark .modal-dialog-breakup{
    background: $header-dark-bg !important;
    *{
        color: $heading-darkColor !important;
    }
    .close_icon{
        filter: invert(1);
    }
}
</style>
