<template>
  <div class="home">
      <innerHeader />
      <SelectVehicle />
    </div>
  </template>
  
  <script>
  import SelectVehicle from "@/components/selectVehicle/SelectVehicle.vue";
  import innerHeader from "@/components/header/innerHeader";
  
  export default {
    name: 'Landing-page',
    components: {
      SelectVehicle,
      innerHeader,
    }
  }
  </script>
  <style scoped lang="scss">
.home{
  min-height: 83vh;
}
</style>