<template>
  <section>
    <div class="row justify-content-center no-gutters">
      <div class="col-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
        <innerHeader/>
        <journeyHeader/>
        <div class="px-3">
          <RegisterForm />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import JourneyHeader from "../components/journeyHeader/journeyHeader.vue"
import RegisterForm from "../components/registerForm/registerForm.vue";
import innerHeader from "../components/header/innerHeader.vue"
export default {
  components: {
    RegisterForm,
    JourneyHeader,
    innerHeader
  },

};
</script>

<style scoped lang="scss">
@import "../assets/scss/components/new-Journey.scss";
</style>
