<template lang="">
    <div>
        <innerHeader/>
        <KycFailed/>
    </div>
</template>
<script>
import KycFailed from '@/components/ckyc/kycFailed.vue';
import InnerHeader from '@/components/header/innerHeader.vue';

export default {
    components:{
        KycFailed,
        InnerHeader
    }
}
</script>
<style lang="">
    
</style>