<template>
  <div
    class="modal varificationModal"
    tabindex="-1"
    role="dialog"
    id="varificationModal"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px" role="document">
      <div class="modal-content">
        <div class="modal-header justify-content-center">
          <h5 class="modal-title text-align-center mt-3">OTP Verification</h5>
        </div>
        <div class="modal-body">
          <p class="mb-0">
            Please enter the OTP sent to your phone <br> number to complete your
            verification
          </p>
          <div class="d-flex justify-content-center" style="flex-direction: row">
          <v-otp-input
            ref="otpInput"
            input-classes="otp-input"
            :conditionalClass="['one', 'two', 'three', 'four']"
            inputType="letter-numeric"
            :num-inputs="4"
            v-model:value="bindValue"
            :should-auto-focus="true"
            :should-focus-order="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </div>
        <p class="text-center">Didn’t get the code? <a href="#">Resend</a></p>

        <button class="btn commonButton mt-3" >Verify & Submit</button>
        <button class="btn backbutton" >Cancel</button>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import VOtpInput from "vue3-otp-input";
export default {
  name: "varificationModal",
  components: {
    VOtpInput,
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";
</style>
