<template lang="">
    <div>
        <innerHeaderDiy/>
        <Paymentpending/>
    </div>
</template>
<script>
import Paymentpending from '@/components/pageLoaders/paymentPending.vue';
import innerHeaderDiy from "@/components/header/innerHeaderDiy";


export default {
    components:{
        Paymentpending,
        innerHeaderDiy
    }
}
</script>
<style lang="">
    
</style>