<template>
    <div class="modal-overlay breakupmodal">
        <div class="modal-dialog-breakup p-3" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-center">
                    <h5 class="modal-title text-align-center d-flex w-100 justify-content-between align-items-center">
                      
                        <h2>Amount Breakup</h2>
                        <span  @click="emitClose()">
            <img src="/images/close-logo.svg" class="close_icon"  />
            </span>
                    </h5>
                </div>
                <div class="modal-body mt-2">
                    <!-- od tp Premium show -->
                    <div class="d-flex justify-content-between align-items-center mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalBasicODPremiumValue)">
                        <div class="d-flex justify-content-between w-100">
                            <p class="text-left" style="text-align: left;">Own Damage Premium</p>
                            <h6> ₹ {{ currency(selectedPlanData[0]?.productDetails?.basicOwnDamagePremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalBasicODPremiumValue) }} </h6>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalBasicTPPremiumValue)">
                        <div class="d-flex justify-content-between w-100">
                            <p class="text-left" style="text-align: left;">Third Party Premium</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.thirdPartyPremiumForEachPolicyTerm.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalBasicTPPremiumValue) }}</h6>
                        </div>
                    </div>
                    <hr>
                    <!-- addon Premium section-->
                    <h3 style="font-size: 14px; text-align: left;" v-if="this.processedAddons.length>0">Add Ons Premium</h3>
                    <div class="">
                        <div v-for="addons in this.processedAddons" :key="addons.optionSelected">
                        <div class="d-flex justify-content-between w-100 mb-2 ">
                            <p class="text-left" style="text-align: left;">{{ addons.optionDescription }}</p>
                            <h6>{{ addons.displayPremium }}</h6>
                        </div>
                        </div>
                        <hr v-if="this.filteredaddon.length>0">

                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueAddons)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Add Ons Premium</p>
                            <h6>₹ {{currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueAddons)}}</h6>
                        </div>
                        <hr v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueAddons)">
                        <!-- discount -->
                        <h3 style="font-size: 14px; text-align: left;" v-if="isValidInput(selectedPlanData[0]?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue) || isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueDiscount)">Discount</h3>
                        <!-- <div class="d-flex justify-content-between w-100 mb-3 ">
                            <p class="text-left" style="text-align: left;">{{selectedPlanData.productDetails?.motorDiscount.discountName }}</p>
                            <h6>₹ {{ selectedPlanData.productDetails?.motorDiscount.premiumForEachPolicyTerm.premiumForEachPPO.premiumForEachPPF.totalPremiumValue }}</h6>
                        </div> -->
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue) ">
                            <p class="text-left" style="text-align: left;">NCB Discount</p>
                            <h6>₹ {{currency(selectedPlanData[0]?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue)}} </h6>
                            
                        </div >
                       <hr v-if="isValidInput(selectedPlanData[0]?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue) ">
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueDiscount)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Discount Premium</p>
                            <h6>₹ {{currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueDiscount) }}</h6>
                        </div>
                        <hr v-if="isValidInput(selectedPlanData[0]?.productDetails?.motorNCBPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue) || isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueDiscount)">
                        <!-- Loading Premium  -->
                        <h3 class="my-2" style="font-size: 14px; text-align: left;" v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueLoading)">Loading Premium</h3>
                        <div class="d-flex justify-content-between w-100 mb-2" v-if="isValidInput(selectedPlanData[0]?.productDetails?.motorLoadingPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue)">
                            <p class="text-left" style="text-align: left;">{{ selectedPlanData[0]?.productDetails?.motorLoadingPremium[0].loadName }}</p>
                            <h6>₹ {{currency(selectedPlanData[0]?.productDetails?.motorLoadingPremium[0].premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].totalPremiumValue)}}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueLoading)">
                            <p class="text-left" style="text-align: left;">Total Loading Premium</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueLoading) }}</h6>
                        </div>
                        <hr v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueLoading) || isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueLoading)">
                        <!-- final Premium -->
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalThirdPartyPremiumValue)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Third Party Premium</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalThirdPartyPremiumValue) }}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalOwnDamagePremiumValue)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Own Damage Premium</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalOwnDamagePremiumValue) }}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueBeforeTax)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Premium Value Before Tax</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumValueBeforeTax) }}</h6>
                        </div>
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalTax)">
                            <p class="text-left" style="text-align: left; font-weight: bold;">Total Tax(@18%)</p>
                            <h6>₹ {{ currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalTax) }}</h6>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between w-100 mb-2 " v-if="isValidInput(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumWithTax)">
                            <p class="text-left text-primary" style="text-align: left;">Total Premium with Tax</p>
                            <h6 class="text-primary">₹ {{currency(selectedPlanData[0]?.productDetails?.totalPremiumDetails[0].totalPremiumWithTax)}}</h6>
                        </div>
                         
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn commonButton" @click="emitClose()">Close</button>
                </div>
                
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
    return{
        selectedPlanData:[],
        filteredaddon:[],
        processedAddons:[]
    }
    },
    mounted(){
       
            if(sessionStorage.getItem("quoteSingleData")){
       this.selectedPlanData[0]=JSON.parse(sessionStorage.getItem("quoteSingleData"))
       let conditions = [
  { premiumIncludedFlag: "No", inbuiltInPlan: "No", mandatoryInProduct: "Yes" },
  { premiumIncludedFlag: "No", inbuiltInPlan: "Yes", mandatoryInProduct: "No" },
  { premiumIncludedFlag: "No", inbuiltInPlan: "Yes", mandatoryInProduct: "Yes" }
];
       this.filteredaddon = this.selectedPlanData[0].productDetails.motorCovers.filter(addon => {
  return !conditions.some(condition =>
    addon.premiumIncludedFlag === condition.premiumIncludedFlag &&
    addon.inbuiltInPlan === condition.inbuiltInPlan &&
    addon.mandatoryInProduct === condition.mandatoryInProduct
  );
});
// Conditions for displaying "Included"
const conditionsForIncluded = [
  { premiumIncludedFlag: "Yes", inbuiltInPlan: "No", mandatoryInProduct: "No" },
  { premiumIncludedFlag: "Yes", inbuiltInPlan: "No", mandatoryInProduct: "Yes" },
  { premiumIncludedFlag: "Yes", inbuiltInPlan: "Yes", mandatoryInProduct: "No" },
  { premiumIncludedFlag: "Yes", inbuiltInPlan: "Yes", mandatoryInProduct: "Yes" }
];

// Function to process premium display
this.processedAddons = this.filteredaddon.map(addon => {
  const matchesCondition = conditionsForIncluded.some(condition =>
    addon.premiumIncludedFlag === condition.premiumIncludedFlag &&
    addon.inbuiltInPlan === condition.inbuiltInPlan &&
    addon.mandatoryInProduct === condition.mandatoryInProduct
  );

  // Modify premium display based on condition
  return {
    ...addon,
    displayPremium: matchesCondition
      ? "Included"
      : `₹ ${addon.premiumForEachPolicyTerm[0]?.premiumForEachPPO[0]?.premiumForEachPPF[0]?.premiumValue || "0"}`

  };
});

}
        },
   
    methods:{
        emitClose(){
            this.$emit('emitClose')
        },
        isValidInput(input) {
            const num = Number(input);
    
          if (!isNaN(num) && num !== 0 && num!==0.00) {
        return true;
    }
    return false;
           },
           currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    }
    
   

}
</script>
<style lang="scss">
@import "../assets/scss/components/modals.scss";
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    z-index: 10;
}

.modal-dialog-breakup{
    position: relative;
    background-color: #FFF;
    max-width: 370px;
    height: 480px;
    overflow-y: auto;
    width: 90%;
    border-radius: 12px;
    padding: 48px 12px 12px;
}
#endclose{
    position: relative;
    left: 0;
}
.dark .modal-dialog-breakup{
    background: $header-dark-bg !important;
    *{
        color: $heading-darkColor !important;
    }
    .close_icon{
        filter: invert(1);
    }
}
</style>