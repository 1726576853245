<template lang="">
    <div class="commonmargin">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-4 col-md-4 col-xl-4 text-center kycform">
          <img src="/images/errorGif.gif" />
          <h1 class="headings text-danger my-3">Document Uploaded Failed !</h1>
          <p>Please Retry</p>
          <router-link to="/kyc-documents" >
            Retry Upload
          </router-link>
         
         
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {};
  </script>
  <style scoped lang="scss">
  @import "../../assets/scss/components/Kyc.scss";
  </style>
  