<template lang="">
    <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8">
            <innerHeader/>
            <journeyHeader/>
            <formWizard/>
            
        </div>
        
    </div>
</template>
<script>
import FormWizard from "../components/formWizard/formWizard.vue"
import journeyHeader from "@/components/journeyHeader/journeyHeader.vue";
import innerHeader from "@/components/header/innerHeader.vue";
export default {
   components:{
    FormWizard,
    journeyHeader,
    innerHeader
   }
}
</script>
<style lang="">
    
</style>